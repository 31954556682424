/* Dependencies */
import React from "react";
import ReactFullpage from "@fullpage/react-fullpage";
/* Components */
import NewsLetter from "./util/NewsLetter";
import { Container, Row, Col } from "react-bootstrap";
/* Style */
import "./App.css";
/* Assets */
import logo from "./img/LOGO.png";
import backgroundDesktop from "./img/DESKTOP.png";
import backgroundMobile from "./img/MOBILE.png";
import mockupDesktop from "./img/MOCKUP.png";
import mockupMobile from "./img/MOCKUP2.png";

const App = () => {
  return (
    <div>
      <ReactFullpage
        licenseKey={"A265316C-06AB4244-B8BC1B6D-1BB7FE17"}
        normalScrollElements=".container"
        autoScrolling={false}
        scrollingSpeed={1000} /* Options here */
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <div className="section">
                <Container fluid className="container">
                  <img
                    src={backgroundDesktop}
                    srcSet={`${backgroundMobile} 1125w, ${backgroundDesktop} 1920w`}
                    alt="background"
                    className="background"
                  />
                  <Row className="flex-center">
                    <Col>
                      <img src={logo} alt="logo" className="logo" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      <div className="infos">
                        <h3>WELCOME TO</h3>
                        <h1>
                          THE IMPACT
                          <br /> GAMES.
                        </h1>
                        <p>
                          The first global e-sport competition dedicated
                          <br /> to impacting the world
                        </p>
                        <p>
                          The Impact Games will start in <b>July 2024</b>
                          <br />
                          Join the list to be among the players
                        </p>
                        <NewsLetter />
                      </div>
                    </Col>
                    <Col xl={6}>
                      <img
                        src={mockupDesktop}
                        srcSet={`${mockupMobile} 1125w, ${mockupDesktop} 1920w`}
                        alt="mockup"
                        className="mockup"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="copyright">FROM WORLD GAME</p>
                    </Col>
                  </Row>
                </Container>
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </div>
  );
};

export default App;
