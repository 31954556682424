import React, { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const NewsLetter = () => {
  const [btnText, setBtnText] = useState("SUBSCRIBE NOW");
  const [userEmail, setUserEmail] = useState("");

  const onSubscribeChange = (value) => {
    if (value) setBtnText("SEND");
    else {
      setBtnText("SUBSCRIBE NOW");
    }
  };
  return (
    <MailchimpSubscribe
      url={
        "https://game.us14.list-manage.com/subscribe/post?u=a590e0863c320e11dc801498b&amp;id=4d7322beb8"
      }
      render={({ subscribe, status, message }) => (
        <div className="wrapper">
          <input
            type="checkbox"
            id="click"
            onChange={(value) => {
              onSubscribeChange(value.target.checked);
              subscribe({ EMAIL: userEmail });
            }}
          />
          <label htmlFor="click" className="btn-1">
            {btnText}
          </label>
          <div className="field">
            <input
              type="text"
              placeholder="EMAIL"
              onChange={(value) => setUserEmail(value.target.value)}
            />
          </div>
        </div>
      )}
    />
  );
};

export default NewsLetter;
